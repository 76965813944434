import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addCustomer } from '../models/customer';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  baseUrl : string = this.base._baseUrl + 'customers'

  constructor(private http: HttpClient, private base : BaseService) { }

    
  addCustomer(payload: addCustomer): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/addupdate`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCustomers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-customers`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getDebitors(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-debitors`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCustomersByCustomer(customer: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/get-customer?customer=${customer}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  
  getCustomersTransactionByCustomer(customer: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/get-customer-transactions?customer=${customer}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCustomerTransByDateRange(customer: string, from: any, to : any): Observable<any> { 
    return this.http.get<any>(this.baseUrl + 
      // get-customers-transactions-by-date?customer=
      `/get-customers-transactions-by-date?customer=${customer}&datefrom=${from}&dateto=${to}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteCustomer(customer: string): Observable<any> {  
    return this.http.delete<any>(this.baseUrl +
      `/delete?customer=${customer}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
}


