import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  private filterCriteria: any = {}; // Define the type of your filter criteria

  getFilterCriteria(): any {
    return this.filterCriteria;
  }

  setFilterCriteria(criteria: any): void {
    this.filterCriteria = criteria;
  }

  constructor() { }
}
