import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addMultipleStock, addStock } from '../models/stock';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  baseUrl : string = this.base._baseUrl + 'stock'

  constructor(private http: HttpClient, private base : BaseService) { }

    
  addStock(payload: addStock): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addMultipleStock(payload: addMultipleStock): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add-multiple`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  updateStock(payload: addStock): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/update`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  uploadStock(appId: string, formData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/upload-stock?user=${appId}`, formData)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }


  getStock(store: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get?store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  searchStock(param: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/search-stock?param=${param}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteStock(appid: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete?appid=${appid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getStockByAppId(appId: string): Observable<any> {  // TODO:  api not correct
    return this.http.get<any>(this.baseUrl +
      `/get-by-appid?appid=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
}
