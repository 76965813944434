import { Constants } from './messages';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  saveToStore(key : string, data : any){
    localStorage.setItem(key, JSON.stringify(data))
  }

  removeItem(key : string){
    localStorage.removeItem(key)
  }

  getUserAppId(){
    let rawid = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let userId = rawid.appid
    return (userId)

  }
  public getFullname(){
    let usern = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let user = usern.fullname
    return user
  }

  getUserStore(){
    let rawid = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let store = rawid.store
    return (store)

  }

  public getDesignation(){
    let usern = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let user = usern.designation
    return user
  }

  getFromStore(key : string){
    return JSON.parse(localStorage.getItem(key))
  }
}
