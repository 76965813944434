<!-- <div class="align-center mt-3 fw-5 text secondary" style="font-size: 12px;"> Successful {{invoiceNo}}</div> -->


<div style="width: 350px;  margin-top: 0px; border-radius: 10px; padding: 10px;" class="my-success-light ">
    <div class="flex-el label success">
        <mat-icon>check</mat-icon> &nbsp;&nbsp; Successful
    </div>
</div>


<div class="flex-center p-2">
    <img src="assets/image/done.png" width="80px" alt="">
</div>

<div class="align-center" style="padding: 30px;">
    <p style="margin-top: 0px; " class="label-big  secondary"> # {{transactionDetails.amountpaid | number : '1.2-2'}}</p>
    <div class="text"> Transaction Successful </div>
</div>


<mat-dialog-actions class="flex-btw">
    <button mat-flat-button color="primary" class=" my-warn stroke-style-secondary" (click)="closeDialog()">
        Cancel
    </button>
    <button mat-flat-button color="primary" class=" myPrimary" style="float: right;" (click)="printInvoice()">
        Print Receipt
    </button>
</mat-dialog-actions>