import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  baseUrl : string = this.base._baseUrl + 'dashboard'

  constructor(private http: HttpClient, private base : BaseService) { }
  
  getAdminDashboardTransDetail(appid: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/admin?admin=${appid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getUserDashboardTransDetail(appid: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/user?user=${appid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
