import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { filter, funds } from '../models/admin';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';



const headers = new HttpHeaders({
  'Content-Type': 'application/json'
})
@Injectable({
  providedIn: 'root'
})

export class FundserviceService {
  baseUrl : string = this.base._baseUrl + 'funds'

  constructor(private http: HttpClient, private base : BaseService) { }

  add(payload: funds): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  filter(payload: filter): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/filter`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  get(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getLogUser(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-by-use?id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getFundsAppid(appid: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-by-appid?appId=${appid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getFundsByUser(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-by-user-today?id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  delete(appid: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/delete?appId=${appid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getTodayr(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-today-funds`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getByDateRange(from: string, to: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-by-date-range?from=${from}&to=${to}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  download(appid:string):Observable<any> {
    return this.http.get<any>(this.baseUrl + 
      `/download-receipt?appid=${appid}`, 
      {headers:headers, responseType: 'blob' as 'json'}
      )
    .pipe(
      catchError( err =>  this.base.errorHandler(err))
    )
  }

  
}
