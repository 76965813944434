import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addAdmin, resetPassword } from '../models/admin';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  baseUrl : string = this.base._baseUrl + 'admin'

  constructor(private http: HttpClient, private base : BaseService) { }


   addAdmin(payload: addAdmin): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addUpdate(payload: addAdmin): Observable<any> {
    return this.http.put<any>(this.baseUrl +
      `/update`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  resetPassword(payload: resetPassword): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/reset-password`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }  


  getLogAdmin(username: string, password: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/log?password=${password}&username=${username}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getLogUser(username: string, password: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/log-user?password=${password}&username=${username}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-users`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getAdminUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-admin-users`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
   
  getStaffUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-only-users`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getUsersByAppId(appId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-users-by-appid?appid=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteStaff(appId: string): Observable<any> {  
    return this.http.delete<any>(this.baseUrl +
      `/delete?appId=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
