<div style="width: 350px;  border-radius: 10px; padding: 10px;" class="my-warn-light ">
    <div class="flex-el label warn">
        <mat-icon>highlight_off</mat-icon> &nbsp;&nbsp; Failed
    </div>
</div>


<div class="flex-center pt-4">
    <img src="assets/image/error.png" width="65px" alt="">
</div>

<div class="align-center" style="padding: 25px;">
    <p class="label-big  warn"> Transaction Failed</p>
</div>


<mat-dialog-actions class="mt-3 flex-center">
    <button mat-flat-button color="primary" class=" myPrimary" (click)="closeDialog()"> Close</button>
</mat-dialog-actions>