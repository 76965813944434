import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-transaction-failed',
  templateUrl: './transaction-failed.component.html',
  styleUrls: ['./transaction-failed.component.scss']
})
export class TransactionFailedComponent implements OnInit {
  
  constructor(private dialogRef: MatDialogRef<TransactionFailedComponent>,
  ) { }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close()
  }

}
