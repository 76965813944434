import { Constants, MessageUtil } from './../../helper/messages';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import {  Router, RoutesRecognized } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { BaseService } from 'src/app/api/base.service';
// import Chart from 'chart.js';

@Component({
  selector: 'app-base-app',
  templateUrl: './base-app.component.html',
  styleUrls: ['./base-app.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class BaseAppComponent implements OnInit {
  showFiller: boolean = true;
  openSideNav: boolean = false
  fullname: string
  designation: string

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  activeComponent: string = 'Overview';

  @Output() public reload: EventEmitter<any> = new EventEmitter<any>()
  imagePreview: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    private app: AppService,   
    private base: BaseService 
  ) {
    
    this.app.titleBase().subscribe(res => {
      this.activeComponent = res
    })
  }

  ngOnInit(): void {
    this.fullname = this.app.helperService.getFullname()
    this.designation = this.app.helperService.getDesignation()
    this.getUsersImage()
  }

  logout() {
    this.app.logout(Constants.LOGIN_USER)
  }
  gotoProfile(){
    this.router.navigate(["app/setting"])
  }

  openSales() {
    this.openSideNav = true
  }

  toggle() {
    event.stopPropagation()
    this.openSideNav = true
  }

  reloadUsers() {
    this.reload.emit()
  }

  closeSidenav() {
    this.openSideNav = false
  }

  getUsersImage() {
    let appid = this.app.helperService.getUserAppId()
    // this.isPhoto = false
    let base =this.base._baseUrl
    this.imagePreview = `${base}settings/preview/photo?appid=${appid}`
    
  }

  menuList = [
    { name: 'Overview', route: 'overview', icon: 'home' },
    { name: 'Customer', route: 'customers', icon: 'people' },
    { name: 'Market Place', route: 'market', icon: 'local_grocery_store' },
    { name: 'Records', route: 'records', icon: 'books' },
    { name: 'Funds', route: 'funds', icon: 'shopping_bag' },
    { name: 'Setting', route: 'setting', icon: 'settings' },
  ];

  show() {
    this.showFiller = this.showFiller = !this.showFiller;
  }
}
