import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public _baseUrl : string = 'http://api.omoowo.store:9090/'
  //public _baseUrl : string = 'http://api.daupex.store:9595/'
  constructor() { }

  errorHandler(error:HttpErrorResponse){
    return Observable.throw(error || "Server Error") 
  } 
}
