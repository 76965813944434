import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageUtil } from 'src/app/helper/messages';

@Component({
  selector: 'app-transaction-success',
  templateUrl: './transaction-success.component.html',
  styleUrls: ['./transaction-success.component.scss']
})
export class TransactionSuccessComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<TransactionSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public transactionDetails,) { }
  ngOnInit(): void {
  }


  closeDialog() {
    this.dialogRef.close()
  }
  printInvoice(){
    this.dialogRef.close(MessageUtil.MODAL_SUCCESS)
  }

}
