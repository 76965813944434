import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addCategory, addProduct } from '../models/product';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  baseUrl : string = this.base._baseUrl + 'product'

  constructor(private http: HttpClient, private base : BaseService) { }


  addCategory(payload: addCategory): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/category/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  addProduct(payload: addProduct): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/addupdate`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  uploadStock(appid: string, file: any): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/upload-stock?user=${appid}`, file)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCategory(store: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/category/get?store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getCategoryByAppId(appId: string): Observable<any> {  
    return this.http.get<any>(this.baseUrl +
      `/category/get-by-appid?appid=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getProduct(store: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get?store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getProductByAppId(appId: string): Observable<any> {  
    return this.http.get<any>(this.baseUrl +
      `/get-by-appid?appid=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getProductByCategory(appId: string): Observable<any> {  
    return this.http.get<any>(this.baseUrl + 
      `/get-by-category?categoryid=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteCategory(appId: number): Observable<any> { 
    return this.http.delete<any>(this.baseUrl +
      `/category/delete?appid=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }


}
