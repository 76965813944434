import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addTransaction, addTransactionToSave, cashOut, makePayment, updateTransaction } from '../models/transaction';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  baseUrl : string = this.base._baseUrl + 'transactions'

  constructor(private http: HttpClient, private base : BaseService) { }

    
  addTransaction(payload: addTransactionToSave): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  returnTransaction(payload: addTransactionToSave): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/return-sales`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  cashOut(payload: cashOut): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/cash-out`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  makePayment(payload: makePayment): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/make-payment`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  updateTransaction(payload: updateTransaction): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/update`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getTransaction(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-details`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getString(): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/string`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getDailyTrans(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/daily-trans`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getTransByDateRange(from: any, to : any,store: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl + 
      `/date-range?from=${from}&to=${to}&store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }


  getTransDetailsBySalesId(salesid: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/get-detail?salesid=${salesid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getUserTodayTransByUser(userAppId : string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-user-today-tran?user=${userAppId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getUserTransByUserAppidDateRange(userappid: string, from: any, to : any): Observable<any> { 
    return this.http.get<any>(this.baseUrl + 
      `/get-user-tran-by-date-range?from=${from}&to=${to}&user=${userappid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }


  getTransactionByUser(appid: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/get-transactions-by-user?user=${appid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getTodayTransaction(store :string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/today-trans?store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  // deleteCustomer(customer: string): Observable<any> {  
  //   return this.http.delete<any>(this.baseUrl +
  //     `/delete?customer=${customer}`)
  //     .pipe(
  //       catchError(err => this.base.errorHandler(err))
  //     )}
}
  
