<!-- <mat-sidenav-container class="sidenav-container">
    <!-- [mode]="(isHandset$ | async) ? 'over' : 'side'" ->
    <mat-sidenav mode="side" [ngClass]="{'sidenav':showFiller == true, 'sidenavs':showFiller == false}" fixedInViewport #drawer [opened]="(isHandset$ | async) === false" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'">
        <div class="sidenav-wrapper nav-scroll custom-scroll-bar">
            <div>
                <div>
                    <mat-icon class="menu_icons align-center">local_printshop</mat-icon>
                    <div *ngIf="showFiller">
                        <p class="label secondary menuName"> TRASACTION APP</p>
                    </div>
                </div>

                <div class="nav-scroll custom-scroll-bar">
                    <mat-nav-list>
                        <ng-container *ngFor="let menu of menuList">
                            <a mat-list-item #menu class="menu" [routerLink]="menu.route" routerLinkActive="router-link-active">
                                <mat-icon class="menu_icons">{{menu.icon}}</mat-icon>
                                <span [ngClass]="{'menuName':showFiller == true, 'menuNames':showFiller == false}">{{menu.name}} </span>
                            </a>
                        </ng-container>
                    </mat-nav-list>
                </div>
            </div>
            <div class="nav-profile" routerLink="/app/settings/user-setting" matTooltip="Settings" matTooltipPosition="right" matTooltipClass="info">
                <mat-icon class="menu_icons">settings</mat-icon>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar class="topbar">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
                <mat-icon>menu</mat-icon>
            </button>
            <div *ngIf="!(isHandset$ | async)" class="minmax" mat-icon-button (click)="show()">
                <span style="position: relative; top: 5px;">
                    <mat-icon>zoom_out_map</mat-icon>
                </span>
            </div>
            <span class="active-title">{{activeComponent}}</span>
            <span class="spacer"></span>
            <mat-form-field class="custom-input global-search" appearance="standard" floatLabel="never">
                <mat-label>Search for Item</mat-label>
                <span matPrefix style="position: relative; top: 7px;">
                    <mat-icon>search</mat-icon> &nbsp;
                </span>
                <span matSuffix style="position: relative; top: 7px; cursor: pointer;color: black;">
                    <mat-icon>clear</mat-icon> &nbsp;
                </span>
                <input type="text" style="color: black;" focus matInput placeholder="Search for Items">
            </mat-form-field>
            <div class="flex-el ml-2">
                <button mat-icon-button [mat-menu-trigger-for]="menu">
                    <mat-icon>settings</mat-icon>
                    <mat-icon>expand_more</mat-icon>
                </button>
                <mat-menu #menu='matMenu'>
                    <button mat-menu-item routerLink="/app/settings/user-setting">
                        <mat-icon>person</mat-icon>
                        &nbsp;
                        <span> User Profile </span>
                    </button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item>
                        <mat-icon style="color: red;">exit_to_app</mat-icon>
                        &nbsp;
                        <span>Logout</span>
                    </button>
                </mat-menu>
            </div>
        </mat-toolbar>
        <div *ngIf="openSideNav" class="custom-salesNav">
            <app-add-sales class="full-width" (reload)="reloadUsers()" (close)="closeSidenav()"></app-add-sales>
        </div>
        <button mat-fab class="mat-bottom-right" matTooltip="Make Sales" (click)="openSales()" matTooltipPosition="before">
            <mat-icon>add</mat-icon>
        </button>

        <div (click)="closeSidenav()">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container> -->

<mat-drawer-container class="mat-container" autosize>
    <mat-drawer #drawer [ngClass]="{'sidenav':showFiller == true, 'sidenavs':showFiller == false}" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

        <mat-toolbar class="navBar2">
            <div *ngIf="showFiller">
                <img class="logo" src="../assets/image/logo/ejire_logo_wx.png">
            </div>
            <div *ngIf="!showFiller">
                <img class="small_logo" src="../assets/image/logo/ejire_logo_w.png">
            </div>
        </mat-toolbar>

        <mat-nav-list class="nav-scroll custom-scroll-bar">
            <a mat-list-item #menu class="menu" [routerLink]="menu.route" matTooltip="{{menu.name}}" matTooltipPosition="right" routerLinkActive="router-link-active" *ngFor="let menu of menuList">
                <mat-icon class="menu_icons">{{menu.icon}}</mat-icon>
                <span [ngClass]="{'menuName':showFiller == true || showFiller == false && (isHandset$ | async),  'menuNames':showFiller == false && !(isHandset$ | async)  }">{{menu.name}}
                    </span>
            </a>
        </mat-nav-list>
    </mat-drawer>

    <div class="sidenav-content">
        <mat-toolbar class="navBar">
            <button type="button" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
                <mat-icon>menu</mat-icon>
            </button>
            <div *ngIf="!(isHandset$ | async)" class="minmax" mat-icon-button (click)="show()">
                <span style="position: relative; top: 5px;">
                    <mat-icon>zoom_out_map</mat-icon>
                </span>
            </div>
            <span style="margin-left: 10px;"> {{activeComponent}} </span>
            <span class="spacer"></span>

            <div class="chip">
                <button mat-icon-button [mat-menu-trigger-for]="menu" style="background-color: white;">
                    <mat-icon class="secondary">expand_more</mat-icon>
                </button>
                <mat-menu #menu='matMenu'>
                    <button mat-menu-item (click)="gotoProfile()">
                        <mat-icon>person</mat-icon>
                        &nbsp;
                        <span>Profile Settings</span>
                    </button>
                    <mat-divider></mat-divider>

                    <button mat-menu-item (click)="logout()">
                        <mat-icon style="color: red;">exit_to_app</mat-icon>
                        &nbsp;
                        <span>Logout</span>
                    </button>
                </mat-menu>
                <div style="display: inline-block; padding-right: 10px; text-align: center; margin-top: -1px; margin-bottom: -10px;">
                    <span class="name"> {{fullname}} </span>
                    <br>
                    <p class="praa">{{designation}}</p>
                </div>
                <img class="placeHold" [src]="imagePreview">
            </div>
        </mat-toolbar>
        <!-- <div *ngIf="openSideNav" class="custom-salesNav"> -->
        <!-- <div *ngIf="openSideNav" class="custom-salesNav">
            <app-add-sales class="full-width" (reload)="reloadUsers()" (close)="closeSidenav()"></app-add-sales>
        </div> -->
        <!-- <button mat-fab class="mat-bottom-right" matTooltip="Make Sales" (click)="openSales()" matTooltipPosition="before">
                <mat-icon>add</mat-icon>
            </button> -->
        <div (click)="closeSidenav()">
            <router-outlet></router-outlet>
        </div>
    </div>

</mat-drawer-container>