import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { addCategory } from '../models/product';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addRecords } from '../models/record';
const headers = new HttpHeaders({
  'Content-Type': 'application/json'
})

@Injectable({
  providedIn: 'root'
})
export class RecordsServiceService {
  baseUrl : string = this.base._baseUrl + 'records'

  
  constructor(private http: HttpClient, private base : BaseService) { }

  
  addRecords(payload: addRecords): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add-record`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  updateRecords(payload: addRecords): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/update-record`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getRecords(store: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-records?store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getLogss(store: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-logs?store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getUserDailyRecords(user: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/users-daily-records?user=${user}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteRecords(appId: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete-record/${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  searchRecords(param: string,store: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/search-records?param=${param}&store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  searchLogs(param: string,store: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/search-logs?param=${param}&store=${store}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getRecord(appid: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list-records-by-appid?appId=${appid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  

}
