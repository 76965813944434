<div class="flex-center">
    <div style="width: 350px;  margin-top: 20px; border-radius: 10px; padding: 10px;" class="my-success-light ">
        <div class="flex-start label"> Transaction Successful </div><br>
        <div class="flex-center">
            <img src="assets/image/done.png" width="70px" alt="">
        </div>
        <div class="label align-center" style="padding-top: 10px;">
            <p><span class="text">  Invoice #:  </span>100923844848DS</p>
            <p style="margin-top: -5px; font-size: 17px;"> #25,000.00</p>
        </div>
    </div>
</div>