import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  baseUrl : string = this.base._baseUrl + 'reports'

  constructor(private http: HttpClient, private base : BaseService) { }

  
  getReport(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-reports-summary`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getAllUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-all-users`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getCustomerTransactionsByAppId(appId: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/get-customer-transactions?customers=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
}
