import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
const headers = new HttpHeaders({
  'Content-Type': 'application/json'
})

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  baseUrl : string = this.base._baseUrl + 'invoice'

  constructor(private http: HttpClient, private base : BaseService) { }

  downloadInvoice(id:string):Observable<any> {
    return this.http.get<any>(this.baseUrl + 
      `/download?appid=${id}`, 
      {headers:headers, responseType: 'blob' as 'json'}
      )
    .pipe(
      catchError( err =>  this.base.errorHandler(err))
    )
  }

  downloadRecords(appid:string):Observable<any> {
    return this.http.get<any>(this.baseUrl + 
      `/download-records?appid=${appid}`, 
      {headers:headers, responseType: 'blob' as 'json'}
      )
    .pipe(
      catchError( err =>  this.base.errorHandler(err))
    )
  }

}
