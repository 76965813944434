import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trans-status',
  templateUrl: './trans-status.component.html',
  styleUrls: ['./trans-status.component.scss']
})
export class TransStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
